import React from "react";
import { Router } from "@reach/router";
import PortfolioPage from "../components/PortfolioPage";
import NotFoundPage from "./404";

const PortfolioPageRouter = (props) => (
  <Router>
    <PortfolioPage {...props} path="/portfolio/" />
    <NotFoundPage default />
  </Router>
);

export default PortfolioPageRouter;
