import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const PortfolioContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allPortfolioJson {
          edges {
            node {
              id
              pageTitle
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
              portfolioSection {
                hideSection
                heading
                content
                tabs {
                  tabName
                  logos {
                    logo
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allPortfolioJson: { edges: portfolioData } }) =>
      children(
        portfolioData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

PortfolioContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default PortfolioContainer;
