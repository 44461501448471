import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import Section from "../Section";
import AspectRatio from "../AspectRatio";
import BgImage from "../BgImage";

import PortfolioContainer from "../../containers/PortfolioContainer";
import PageHeroBanner from "../PageHeroBanner";
import LinkTo from "../LinkTo";
import MarkdownToHtml from "../MarkdownToHtml";

const PortfolioPage = () => {
  const setSection = (sectionDetails) => {
    const { heroBannerSection, portfolioSection } = sectionDetails;
    const { tabs } = portfolioSection;
    const [tab, setTab] = useState(tabs[0]);
    const tabArray = [];
    const theme = useContext(ThemeContext);
    const gap = "1.5rem";
    const handleLogoImage = (logo) => (
      <AspectRatio
        ratio={[16, 9]}
        sx={{
          bg: "white",
          position: "relative",
        }}
      >
        <BgImage
          name={logo}
          page="portfolio"
          sx={{
            backgroundSize: "contain",
            position: "absolute",
            width: "100%",
          }}
        />
      </AspectRatio>
    );
    tabs.map((t) => {
      tabArray.push(t.tabName);
    });

    const isActiveTab = tabArray.map((t) => {
      if (t === tab.tabName) {
        return true;
      }
    });

    const toggleActiveButton = (tab) => {
      setTab(tab);
    };

    return (
      <>
        {heroBannerSection && (
          <PageHeroBanner
            actionButton={heroBannerSection.actionButton}
            bannerImage={heroBannerSection.bannerImage}
            content={{
              heading: heroBannerSection.heading,
              text: heroBannerSection.content,
            }}
          />
        )}
        {portfolioSection && !portfolioSection.hideSection && (
          <Section>
            {portfolioSection && (
              <MarkdownToHtml
                content={portfolioSection.content}
                heading={portfolioSection.heading}
                sx={{
                  mb: 5,
                  textAlign: ["center", null, null, "left"],
                }}
              />
            )}
            <Box
              sx={{
                bg: "grayBackground",
                pb: 5,
                px: gap,
                ...theme.designElement.rightBottom,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {tabs &&
                  tabs.map((tab, index) => (
                    <Box
                      onClick={() => toggleActiveButton(tab)}
                      sx={{
                        alignItems: "center",
                        cursor: isActiveTab[index] ? "default" : "pointer",
                        px: [2, null, null, gap],
                        py: 3,
                      }}
                    >
                      <Box
                        sx={{
                          ":before": {
                            bg: "grayBorder",
                            bottom: "-15px",
                            content: "''",
                            height: "3px",
                            mx: "auto",
                            position: "absolute",
                            width: isActiveTab[index] ? "100%" : "0%",
                          },
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            color: "text.para",
                            fontSize: [1, null, 2, 2],
                            fontWeight: "bold",
                            my: "auto",
                            // px: 2,
                          }}
                        >
                          {`${tab.tabName.toUpperCase()}`}
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {tab.logos &&
                  tab.logos.map((l) => {
                    const { logo, url } = l;
                    return (
                      <>
                        {url ? (
                          <Box
                            sx={{
                              p: gap,
                              position: "relative",
                              width: ["100%", null, "50%", "25%"],
                            }}
                          >
                            <LinkTo
                              isLink={url}
                              sx={{
                                bg: "white",
                                display: "flex",
                                flexDirection: "column",
                                p: 4,
                              }}
                              target="_blank"
                            >
                              {handleLogoImage(logo)}
                            </LinkTo>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              p: gap,
                              position: "relative",
                              width: ["100%", null, "50%", "25%"],
                            }}
                          >
                            <Box
                              sx={{
                                bg: "white",
                                display: "flex",
                                flexDirection: "column",
                                p: 4,
                              }}
                            >
                              {handleLogoImage(logo)}
                            </Box>
                          </Box>
                        )}
                      </>
                    );
                  })}
              </Box>
            </Box>
          </Section>
        )}
      </>
    );
  };
  return (
    <PortfolioContainer>
      {(portfolioData) => {
        const portfolioId = "portfolio";
        const portfolioInfo = portfolioData.find(
          (data) => data.id === portfolioId
        );
        return (
          <>
            <SEO pathname="portfolio" templateTitle={portfolioInfo.pageTitle} />
            {setSection(portfolioInfo)}
          </>
        );
      }}
    </PortfolioContainer>
  );
};

PortfolioPage.propTypes = {};

export default PortfolioPage;
